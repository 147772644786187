<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">DAILY BANK DEPOSITED REPORT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-select
              v-if="!saving_data"
              v-model="month_of_id"
              class="mx-2"
              dense
              outlined
              label="Month Of"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              :rules="rules.combobox_rule"
              @change="selected_month"
            ></v-select>
            <v-select
              v-if="!saving_data"
              v-model="category_id"
              class="mx-2"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              v-if="!saving_data"
              v-model="view_by"
              class="mx-2"
              dense
              outlined
              label="View By"
              :items="['Line Graph','Figures']"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-progress-circular
              v-if="saving_data"
              :size=50
              :width="5"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
      </v-form>
      <div v-if="view_by==='Figures'">
        <v-data-table dense
                      :key="this.key"
                      class="mt-3"
                      :headers="headers"
                      :items="data_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:top v-if="data_items.length>0">
            <h2 v-if="!saving_data" class="mx-2">PRINT
              <v-icon
                class="mr-2"
                color="success"
                @click="print_data"
              >
                {{icons.mdiPrinter}}
              </v-icon>
            </h2>

            <v-progress-circular
              :size=50
              :width="5"
              color="success"
              indeterminate
              v-else
            ></v-progress-circular>
          </template>
        </v-data-table>
      </div>
      <div v-else>
        <LinearChart
          :key="this.$vuetify.theme.dark || this.key"
          :dataPoints="this.dataPoints"
          :report_title="'DAILY BANK DEPOSITED REPORT'"
          :report_details="this.report_details"
        ></LinearChart>
      </div>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiCheck, mdiClose, mdiPrinter} from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import LinearChart from '@/views/pages/charts/Chart'

  const initialState = () => {
    return {
      key: 0,
      saving_data: false,
      alert: false,
      option_items: [],
      category_items: [],
      month_of_items: [],
      data_items: [],
      data_items2: [],
      dataPoints: [],
      category_id: '',
      view_by: '',
      report_details: '',
      option: '',
      alert_message: '',
      selection: '',
      month_of_id: '',
      total_amount: '0.00',
      headers_data: {},
      headers: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
      LinearChart,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPrinter,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
      ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('bank_transaction', ['initialize_daily_bank_deposit_report', 'daily_bank_deposit_report']),
      ...mapActions('transaction_months', ['list_of_transaction_month']),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      async selected_month() {
        this.data_items = []
        this.dataPoints = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        await this.initialize_daily_bank_deposit_report({
          month_of_id: this.month_of_id,
        })
          .then(response => {
            this.category_items = response.data
            this.saving_data = false
            this.alert = false

          })
          .catch(error => {
            console.log(error)
          });
        if (this.category_id != '') {
          await this.selected_category()
        }
      },
      async selected_category() {
        this.data_items = []
        this.dataPoints = []
        this.saving_data = true
        this.alert = true
        this.alert_message = 'PLEASE WAIT...'
        var dd = []
        dd.push({text: 'Bank Code', value: 'bank_code', sortable: false})
        var array_of_dates = this.category_items[this.category_items.map(function (x) {
          return x.id;
        }).indexOf(this.category_id)].date_data
        array_of_dates.forEach(function (item) {
          dd.push({
            text: moment(item.date).format('D'),
            value: item.date,
            sortable: false
          })
        })
        this.headers = dd
        this.key++

        if (this.$refs.form.validate()) {
          await this.daily_bank_deposit_report({
            category_id: this.category_id,
            month_of_id: this.month_of_id,
            dates_data: array_of_dates,
          })
            .then(response => {
              this.saving_data = false
              this.alert = false
              this.data_items = response.data

            })
            .catch(error => {
              console.log(error)
            });
          if (this.view_by != 'Figures') {
            this.report_details = 'Month of: ' + (this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of_id)].month_of) + '         Category: ' + (this.category_items[this.category_items.map(function (x) {
              return x.id;
            }).indexOf(this.category_id)].category)
            var dd = []
            await this.data_items.forEach(function (item) {
              if (item.id === 0) {
                Object.entries(item).map(([key, val] = entry) => {
                  var randomColor = Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
                  if (key != 'id' && key != 'bank_code') {
                    dd.push({
                      label: key,
                      y: val != '' ? parseFloat(val.replaceAll(',', '')) : 0,
                      color: "#" + randomColor
                    })
                  }
                });
              }
            })
            this.dataPoints = [
              {
                type: "spline",
                startAngle: 90,
                yValueFormatString: "#,##0.00",
                toolTipContent: "<span style='\"'color: {color};'\"'>{label}</span>, {y}",
                dataPoints: dd,
              }
            ]
            this.key++
          }
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []


          widths = [200, 70, 70, 70, 70]
          payments_array.push([
            {text: 'Bank Code', alignment: 'left', style: 'main_info',},
            {text: 'Last Month Deposit', alignment: 'left', style: 'main_info'},
            {text: 'Current Deposit', alignment: 'left', style: 'main_info'},
            {text: 'Percentage', alignment: 'left', style: 'main_info'},
          ])
          this.data_items.forEach(function (item) {
            payments_array.push([
              {
                text: item.bank_code,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.last_month_deposit === 0 ? '' : (item.last_month_deposit / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.total_deposit === 0 ? '' : (item.total_deposit / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.percentage,
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })
          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Month of: ',
                    {
                      text: this.month_of_items[this.month_of_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.month_of_id)].month_of,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Category: ',
                    {
                      text: this.category_items[this.category_items.map(function (x) {
                        return x.id;
                      }).indexOf(this.category_id)].category,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: imgData, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'GOODLIFE GROUP OF COMPANIES',
                      {text: this.address, style: 'subheader'},
                      {
                        text: this.contact,
                        style: 'subheader',
                      },
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header',
                  },
                ],
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'MONTHLY DAMAYAN INCOME COMPARISON REPORT',
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {image: this.damayan, width: 54, height: 40, style: 'logo'},
                  {image: this.chapel, width: 54, height: 40, style: 'logo'},
                  {image: this.factory, width: 54, height: 40, style: 'logo'},
                  {image: this.coop, width: 54, height: 40, style: 'logo'},
                  {image: this.printing, width: 54, height: 40, style: 'logo'},
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    },
  }
</script>
